import Cookies from 'js-cookie'

export const EnrichSentryScope = (context) => {
  if (typeof context === 'undefined') return (scope) => {}

  return (scope) => {
    if (context.transaction) scope.setTransactionName(context.transaction)

    context.tags?.forEach(([tag, value]) => {
      scope.setTag(tag, value)
    })

    const userId = Cookies.get('bl_logged_in_user')
    if (userId) scope.setUser({ id: userId })
  }
}
