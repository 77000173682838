const DEV_SAMPLE_RATE = 1.0
const PAGELOAD_SAMPLE_RATE = 0.1
const HIGH_TRAFFIC_PAGELOAD_SAMPLE_RATE = 0.05
const HIGH_TRAFFIC_PAGES = [
  '/list/:url_slug', // registry view (self and guest)
  '/hello-baby/:url_slug', // guides
  '/find', // registry search
]

const getPageLoadSampleRate = (name) => {
  if (HIGH_TRAFFIC_PAGES.includes(name)) {
    return HIGH_TRAFFIC_PAGELOAD_SAMPLE_RATE
  }
  return PAGELOAD_SAMPLE_RATE
}

export const getTracesSampleRate = (host, name, operation) => {
  if (host.includes('test') || host.includes('staging')) return DEV_SAMPLE_RATE
  if (operation == 'pageload') return getPageLoadSampleRate(name)
  return 0.0
}
