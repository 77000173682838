import { configureScope, init } from '@sentry/react'
import { httpClientIntegration } from '@sentry/integrations'
import { BrowserTracing } from '@sentry/browser'
import { EnrichSentryScope } from './lib/enrich-sentry-scope'
import { getTracesSampleRate } from './lib/get-traces-sample-rate'
import { shouldIgnoreError } from './lib/sentry-helpers'

const getAnalyticsInfo = (key) =>
  typeof window !== 'undefined' && window?.__BL_ANALYTICS__?.[key]

export const initSentry = () => {
  const environment = getAnalyticsInfo('environment')
  const release = getAnalyticsInfo('app_version')

  try {
    init({
      dsn: 'https://4ef7375851964bd1bd2bb051a26158d1@o1363869.ingest.sentry.io/4505195836866560',
      environment,
      initialScope: EnrichSentryScope(__SENTRY_TRANSACTION_CONTEXT__),
      integrations: [httpClientIntegration(), new BrowserTracing()],
      release,
      sampleRate: 0.1,
      beforeSend: (error, hint) => {
        return shouldIgnoreError(error, hint) ? null : error
      },
      tracePropagationTargets: [
        /^https:\/\/babylist\.test/,
        /^https:\/\/www\.babylist\.test/,
        /^https:\/\/(?:staging\d*\.)?babylist\.com/,
        /^https:\/\/canary\.babylist\.com/,
        /^https:\/\/www\.babylist\.com/,
        /^https:\/\/babylist\.com/,
      ],
      tracesSampler: (samplingContext) => {
        const {
          location: { host },
          transactionContext: { name, op },
        } = samplingContext

        return getTracesSampleRate(host, name, op)
      },
    })

    return { configureScope }
  } catch (e) {
    console.error('Failed to initialize Sentry', e)
    return null
  }
}

export const mockSentry = () => ({
  configureScope: (func) => {
    func({
      setTag: (tag, value) =>
        console.log(`Sentry.setTag(${JSON.stringify([tag, value])})`),
      setUser: (user) => console.log(`Sentry.setUser(${JSON.stringify(user)})`),
      setTransactionName: (transaction) =>
        console.log(
          `Sentry.setTransactionName(${JSON.stringify(transaction)})`
        ),
    })
  },
})
